import "../styles/home.scss";
import "../styles/shipments.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useState, useEffect, useRef } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { UserProfileData } from "../library/constants";
import {
  getShipmentsURL,
  searchForCustomersURL,
  searchForShipmentsURL,
  advanceShipmentSearchUrl,
} from "../library/URLs";
import axios from "axios";
import styled from "styled-components";
import * as React from "react";
import moment from "moment";
import * as BiIcons from "react-icons/bi";
import RouteShipmentDialog from "../modals/RouteShipmentDialog";
import CustomDateRangeDialog from "../modals/CustomDateRangeDialog";
import { SessionManagement } from "../library/SessionManagement";
import SubscriptionInactive from "../components/SubscriptionInactive";
import SearchShipmentsDialog from "../modals/SearchShipmentsDialog";
import { BsChevronDown, BsChevronUp, BsDot } from "react-icons/bs";
import * as ExcelJS from "exceljs";
import AddBatchDialog from "../modals/AddBatchDialog";
import MassUpdateShipmentStatus from "../modals/MassUpdateShipmentStatus";
import ShipmentsIcon from "../assets/shipments/deployed_code_history.svg";
import { SearchBox } from "../components/InputFields";
import Pagination from "../components/Pagination";
import Tooltip from "@mui/material/Tooltip";
import ExportIcon from "../assets/shipments/file_save.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EmptyScreen from "../components/EmptyScreen";

function titleCase(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const Shipments = () => {
  const form = useRef(null);
  const navigator = useNavigate();
  const params = useParams();

  const [searchParam] = useSearchParams();

  const page = searchParam.get("page");

  useEffect(() => {
    if (page) {
      setCurrentPage(parseInt(page));
      window.scrollTo(0, 0);
    }
  }, [page]);

  const [chosenPaymentStatus, setChosenPaymentStatus] = useState("");
  const [chosenDate, setChosenDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const shipmentType = titleCase(params["shipmentType"].replace("-", " "));

  const [selectedShipments, setSelectedShipments] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [currentOffsetDate, setCurrentOffsetDate] = useState("");
  const [currentOffsetID, setCurrentOffsetID] = useState(0);

  const [isSearchShipmentDialogVisible, setIsSearchShipmentDialogVisible] =
    useState(false);
  const [isCustomDateRangeDialogVisible, setIsCustomDateRangeDialogVisible] =
    useState(false);

  const [resultContainerVisibility, setResultContainerVisibility] =
    useState(false);
  const [customerQueryResults, setCustomerQueryResults] = useState([]);
  const [isOptionSelected, setIsOptionSelected] = useState("");

  const [showMassUpdate, setShowMassUpdate] = useState(false);

  const [shipments, setShipments] = useState([]);
  const [totalPages, setTotalPages] = useState();

  const [addBatch, setAddBatch] = useState(false);

  const [isQuerySearching, setIsQuerySearching] = useState(false);

  const [query, setQuery] = useState("");
  const [openExport, setOpenExport] = useState(null);

  // advance search fields
  const [advanceSearchQueries, setAdvanceSearchQueries] = useState({
    receiver_name: "",
    receiver_address: "",
    shipment_types: [],
    carriers: [],
    start_date: "",
    end_date: "",
    start_date_format: "",
    end_date_format: "",
    shipment_number: "",
    "3pl_tracking_number": "",
  });
  const [loading, setLoading] = useState(false);

  const fetchShipments = async () => {
    const data = {
      limit: 20,
      offset_date: currentOffsetDate,
      offset_id: currentOffsetID,
      status: shipmentType === "All Shipments" ? "All" : shipmentType,
      payment_status: chosenPaymentStatus,
      date: chosenDate,
      paginated: "Yes",
      page: page ?? "1",
    };

    const newData = { ...data, ...UserProfileData() };
    setIsLoading(true);

    await axios
      .post(getShipmentsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);

        if (response["data"]["success"] === false) return;

        const newShipments = response["data"]["data"]["shipments"];
        const total_pages = response["data"]["data"]["total_pages"];

        setShipments(newShipments);
        setTotalPages(parseInt(total_pages));
        window.scrollTo(0, 0);
      });
  };

  useEffect(() => {
    fetchShipments();
    // eslint-disable-next-line
  }, [page]);

  // toggle selected shipments
  const handleShipmentToggle = (id) => {
    let selectedShipmentCopy = [...selectedShipments];

    if (selectedShipmentCopy.includes(id)) {
      selectedShipmentCopy = selectedShipmentCopy.filter(
        (shipmentId) => shipmentId !== id
      );
    } else {
      selectedShipmentCopy.push(id);
    }
    setSelectedShipments(selectedShipmentCopy);
  };

  const handleSelectAll = () => {
    let selectedShipmentCopy = [...selectedShipments];

    console.log("faf", shipments.length);
    console.log("gga", selectedShipments);
    if (selectedShipments.length === shipments.length) {
      selectedShipmentCopy = [];
      console.log("full");
    } else {
      let selection = shipments?.map((shipment) => shipment.shipment_no);
      selectedShipmentCopy = selection;
      console.log("empty");
    }
    setSelectedShipments(selectedShipmentCopy);
  };

  const handleClick = (shipmentID) => {
    navigator("/shipment/" + shipmentID);
  };

  const exportToXlsx = () => {
    let aajShipments = shipments?.filter(
      (shipment) => shipment?.processor === "AAJ (Fedex)"
    );

    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("AAJ Shipments");
    sheet.properties.defaultRowHeight = 40;

    sheet.getRow(1).border = {
      // top: { style: "thick", color: { argb: "074B8A" } },
      // left: { style: "thick", color: { argb: "074B8A" } },
      bottom: { style: "thick", color: { argb: "074B8A" } },
      right: { style: "thin", color: { argb: "074B8A" } },
    };

    sheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "008561" },
    };

    sheet.getRow(1).font = {
      // name: "Comic Sans MS",
      // family: 4,
      size: 16,
      bold: true,
      color: { argb: "000000" },
    };

    sheet.columns = [
      {
        header: "S/N",
        key: "sn",
        width: 5,
      },
      {
        header: "SHIPMENT ID",
        key: "id",
        width: 30,
      },
      {
        header: "SHIPMENT TYPE",
        key: "shipment_type",
        width: 40,
      },
      {
        header: "DATE",
        key: "date_time",
        width: 40,
      },
      {
        header: "SENDER NAME",
        key: "sender_name",
        width: 40,
      },
      {
        header: "SENDER PHONE NUMBER",
        key: "sender_phone_no",
        width: 40,
      },
      {
        header: "PICKUP ADDRESS",
        key: "pick_up_location",
        width: 60,
      },
      { header: "RECEIVER NAME", key: "recipient_name", width: 40 },
      { header: "RECEIVER PHONE NUMBER", key: "recipient_phone_no", width: 40 },
      {
        header: "DROPOFF ADDRESS",
        key: "drop_off_location",
        width: 60,
      },
      {
        header: "AMOUNT",
        key: "total_amount",
        width: 60,
      },
      {
        header: "PAYMENT METHOD",
        key: "payment_method",
        width: 40,
      },
      {
        header: "PAYMENT STATUS",
        key: "paid_status",
        width: 40,
      },
      {
        header: "SHIPMENT STATUS",
        key: "status",
        width: 40,
      },
      {
        header: "WEIGHT",
        key: "weight",
        width: 40,
      },
      {
        header: "ITEM DESCRIPTION",
        key: "item_name",
        width: 70,
      },
      {
        header: "ITEM VALUE",
        key: "item_value",
        width: 40,
      },
      {
        header: "APPROVED BY",
        key: "approved_by",
        width: 40,
      },
      {
        header: "SHIPMENT TRACKING NUMBER",
        key: "shipment_no",
        width: 40,
      },
      {
        header: "RIDER",
        key: "rider_name",
        width: 40,
      },
      // {
      //     header: "CARRIER",
      //     key: "carrier",
      //     width: 40,
      // },
    ];

    sheet.insertRow(1, [
      "",
      "",
      "",
      `
          SHIPMENT REPORT
          `,
    ]);
    const first_row = sheet.getRow(1);
    first_row.height = 120;
    first_row.font = {
      size: 18,
      bold: true,
      color: { argb: "074B8A" },
    };
    first_row.border = {
      // top: { style: "thick", color: { argb: "074B8A" } },
      left: { style: "thick", color: { argb: "FFFFFF" } },
      bottom: { style: "thick", color: { argb: "074B8A" } },
      // right: { style: "thick", color: { argb: "074B8A" } },
    };
    first_row.alignment = { horizontal: "center" };

    // const result = toDataURL(User?.company_logo_url);
    // const result = User?.company_logo_url;

    // worksheet.addImage(imageId2, {
    //   tl: { col: 1.5, row: 1.5 },
    //   br: { col: 3.5, row: 5.5 }
    // });
    aajShipments?.forEach((shipment, id) => {
      sheet.addRow({
        sn: id + 1,
        id: `${shipment.id ?? "N/A"}`,
        shipment_type: `${shipment?.shipment_type ?? "N/A"}`,
        date_time: `${shipment?.date_time ?? "N/A"}`,
        sender_name: `${shipment?.sender_name ?? "N/A"}`,
        sender_phone_no: `${shipment?.sender_phone_no ?? "N/A"}`,
        pick_up_location: `${shipment?.pick_up_location ?? "N/A"}`,
        recipient_name: `${shipment?.recipient_name ?? "N/A"}`,
        recipient_phone_no: `${shipment?.recipient_phone_no ?? "N/A"}`,
        drop_off_location: `${shipment?.drop_off_location ?? "N/A"}`,
        item_name: `${shipment?.item_name ?? "N/A"}`,
        total_amount: `₦${shipment?.total_amount ?? "0"}`,
        payment_method: `${shipment?.payment_method ?? ""}`,
        paid_status: `${shipment?.paid_status ?? ""}`,
        status: `${shipment?.status ?? ""}`,
        weight: `${shipment?.weight ?? ""}`,
        shipment_no: `${shipment?.shipment_no ?? "N/A"}`,
        item_value: `${shipment?.item_value ?? ""}`,
        approved_by: `${shipment?.approved_by ?? ""}`,
        rider: `${shipment?.rider_name ?? ""}`,
        // pickup: `₦${shipment?.total_amount ?? "0"}`,
        // dropoff: `₦${shipment?.total_amount ?? "0"}`,
        //   carrier: `${shipment?.processor}`,
      });
    });
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `AAJ_Shipments.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const exportToCSV = () => {
    if (shipments.length === 0) return;

    let csvContent = "data:text/csv;charset=utf-8,";

    csvContent +=
      "Shipment ID,Shipment Type,Date,Sender Name,Sender Phone Number,Pickup Address,Receiver Name,Receiver Phone Number,Dropoff Address,Amount,Pickup Transporter, Dropoff Transporter,Payment Method,Payment Status,Shipment Status,Weight,Description,Item Value,Approved By,Tracking Number" +
      "\r\n";

    shipments.forEach(function (shipment) {
      let id = shipment.id;

      let senderDetails =
        shipment.shipment_type +
        "," +
        shipment.date_time +
        "," +
        shipment.sender_name +
        "," +
        shipment.sender_phone_no +
        "," +
        shipment.pick_up_location.replaceAll(",", "");
      let receiverDetails =
        shipment.recipient_name +
        "," +
        shipment.recipient_phone_no +
        "," +
        shipment.drop_off_location.replaceAll(",", "");
      let packageDetails =
        shipment.total_amount.replaceAll(",", "") +
        "," +
        shipment.pick_up_transporter +
        "," +
        shipment.drop_off_transporter +
        "," +
        shipment.payment_method +
        "," +
        shipment.paid_status +
        "," +
        shipment.status +
        "," +
        shipment.weight +
        "," +
        shipment.item_name.replaceAll(",", "") +
        "," +
        shipment.item_value_currency +
        " " +
        shipment.item_value +
        "," +
        shipment.approved_by_name +
        "," +
        shipment.shipment_tracking_number;

      const row =
        id + "," + senderDetails + "," + receiverDetails + "," + packageDetails;

      csvContent += row + "\r\n";
    });

    const encodedURI = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedURI);
    link.setAttribute("download", "shipments1.csv");
    document.body.appendChild(link);

    link.click();
  };

  const onChangePaymentStatus = (event) => {
    setChosenPaymentStatus(event.target.value);
  };

  const toggleCustomDateRangeDialog = () => {
    setIsCustomDateRangeDialogVisible(!isCustomDateRangeDialogVisible);
  };

  const onChangeShipmentDate = (event) => {
    if (event.target.value === "custom") {
      toggleCustomDateRangeDialog();
    } else {
      setChosenDate(event.target.value);
    }
  };

  const getReports = () => {
    if (chosenPaymentStatus !== "" && chosenDate !== "") {
      setIsLoading(true);
      setCurrentOffsetID(0);
      setCurrentOffsetDate("");
      setIsOptionSelected(chosenPaymentStatus + chosenDate);
    }
  };

  const handleCloseSearchShipmentsDialog = () => {
    setIsSearchShipmentDialogVisible(false);
  };

  const openSearchShipmentsDialog = () => {
    setIsSearchShipmentDialogVisible(true);
  };

  const searchForCustomers = (e) => {
    e.preventDefault();
  };

  const handleQueryChange = () => {
    const formData = new FormData(form.current);
    const query = formData.get("query");
    setQuery(query);

    if (query.toString().trim() === "") {
      setIsQuerySearching(false);
      setResultContainerVisibility(false);

      return null;
    }

    const formValues = {};
    formValues["query"] = query;
    formValues["limit"] = "5";

    const newData = { ...formValues, ...UserProfileData() };

    setIsQuerySearching(true);
    setResultContainerVisibility(true);

    axios.post(searchForShipmentsURL, newData).then(function (response) {
      console.log(response);

      if (response["data"]["success"] === false) return;

      const customers = response["data"]["data"];

      setCustomerQueryResults(customers);
    });
  };

  const handleAdvanceSearchQuery = () => {
    const formValues = advanceSearchQueries;
    // formValues["query"] = query;
    formValues["limit"] = "5";

    const newData = { ...formValues, ...UserProfileData() };

    setIsQuerySearching(true);
    setResultContainerVisibility(true);
    setLoading(true);

    axios
      .post(advanceShipmentSearchUrl, newData)
      .then(function (response) {
        setLoading(false);
        handleCloseSearchShipmentsDialog();

        const newShipments = response["data"]["data"];

        setShipments(newShipments);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onSubmit = () => {
    setSelectedShipments([]);
  };

  const massUpdateIDs = () => {
    let shipment_ids = shipments
      ?.filter(
        (ship) =>
          selectedShipments?.includes(ship?.shipment_no) &&
          (ship?.processor === "Ella Logistics Cargo (Door to Door)" ||
            ship?.processor === "Ella Logistics Cargo")
      )
      ?.map((ship) => ship?.id);

    let joined_string = shipment_ids?.join("|") ?? "";

    return joined_string;
  };

  const refresh = () => {
    window.location.reload();
  };

  const shortenTexts = (text) => {
    if (text) {
      if (text?.length > 16) {
        return `${text?.slice(0, 16)}...`;
      } else {
        return text;
      }
    }
  };

  const paginate = (num) => {
    setCurrentPage(num);
    // fetchShipments(num);
    navigator(`/shipments/all-shipments?page=${num}`);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
    // fetchShipments(currentPage + 1);
    navigator(`/shipments/all-shipments?page=${currentPage + 1}`);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
    // fetchShipments(currentPage - 1);
    navigator(`/shipments/all-shipments?page=${currentPage - 1}`);
  };

  const openNewTab = (shipment_id) => {
    const currentDomain = window.location.origin;

    const fullUrl = `${currentDomain}${`/shipment/${shipment_id}`}`;

    window.open(fullUrl, "_blank");
  };

  const open = Boolean(openExport);
  const handleClickExport = (event) => {
    setOpenExport(event.currentTarget);
  };
  const handleClose = () => {
    setOpenExport(null);
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />

          <div className="container-fluid shipments-container">
            <SearchShipmentsDialog
              isModalVisible={isSearchShipmentDialogVisible}
              handleCloseDialog={handleCloseSearchShipmentsDialog}
              advanceQueries={advanceSearchQueries}
              setAdvanceSearchQueries={setAdvanceSearchQueries}
              handleAdvanceSearchQuery={handleAdvanceSearchQuery}
              loading={loading}
            />

            <CustomDateRangeDialog
              isModalVisible={isCustomDateRangeDialogVisible}
              handleCloseDialog={(e) => {
                if (typeof e === "string") {
                  console.log("The chosen date is " + e.toString());
                  setChosenDate(e);
                }
                toggleCustomDateRangeDialog("close");
              }}
            />

            <AddBatchDialog
              isModalVisible={addBatch}
              handleCloseDialog={() => setAddBatch(false)}
              shipments={selectedShipments}
              onSubmit={onSubmit}
            />

            <MassUpdateShipmentStatus
              isModalVisible={showMassUpdate}
              handleCloseDialog={() => setShowMassUpdate(false)}
              shipmentIDs={massUpdateIDs()}
              fetchShipmentInfo={refresh}
            />

            <div
              className="card border-0 p-4 min-vh-100"
              style={{ backgroundColor: "#FFF" }}
            >
              <div className="d-flex w-100 justify-content-between align-items-center mb-4 ">
                <div className="d-flex align-items-center flex-row gap-2 ">
                  <img src={ShipmentsIcon} alt="shipments" />
                  <h5 className="mb-2">{shipmentType}</h5>
                </div>

                <div className="d-flex flex-column flex-md-row gap-3">
                  {selectedShipments.length < 1 && (
                    <div className="d-flex align-items-center gap-3">
                      <form
                        className="query-result-container"
                        ref={form}
                        onSubmit={searchForCustomers}
                      >
                        <SearchBox
                          onChange={handleQueryChange}
                          name="query"
                          value={query}
                          placeholder="Search using receiver's name, dropoff location or shipment number"
                        />
                        <div
                          className={`query-result w-100 ${
                            resultContainerVisibility === true
                              ? "result-visible"
                              : null
                          }`}
                        >
                          {setIsQuerySearching === true
                            ? null
                            : customerQueryResults?.map((customer) => {
                                return (
                                  <div
                                    key={customer.id}
                                    className="d-flex flex-column"
                                    onClick={() => handleClick(customer.id)}
                                  >
                                    <span className="d-flex align-items-center">
                                      <span style={{ fontSize: 13 }}>
                                        {customer.recipient_name}
                                      </span>
                                    </span>
                                    <span style={{ fontSize: 15 }}>
                                      {customer.drop_off_location}
                                    </span>
                                  </div>
                                );
                              })}
                        </div>
                      </form>

                      <button
                        className="btn-secondary"
                        style={{ fontSize: 12 }}
                        onClick={openSearchShipmentsDialog}
                      >
                        Advanced Search
                      </button>
                    </div>
                  )}

                  {/* {UserProfileData()?.company_id === "99" && (
                    <input
                      className="confirm-button btn-primary"
                      type="button"
                      onClick={exportToXlsx}
                      value="Download AAJ Fedex"
                    />
                  )} */}

                  {selectedShipments.length > 0 && (
                    <input
                      className="confirm-button btn-primary"
                      type="button"
                      onClick={() => setAddBatch(true)}
                      value="Add To Batch"
                    />
                  )}

                  {selectedShipments.length > 0 &&
                    UserProfileData()?.company_id === "65" && (
                      <input
                        className="confirm-button btn-primary"
                        type="button"
                        onClick={() => setShowMassUpdate(true)}
                        value="Update Status"
                      />
                    )}

                  {/* <input
                    className="confirm-button btn-primary"
                    type="button"
                    onClick={exportToCSV}
                    value="Export To CSV"
                  /> */}
                  <div>
                    <button
                      className="feature-btn"
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClickExport}
                    >
                      <img src={ExportIcon} alt="export" />
                      Export
                      {open ? <BsChevronUp /> : <BsChevronDown />}
                    </button>
                    <Menu
                      id="basic-menu"
                      anchorEl={openExport}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          exportToCSV();
                          handleClose();
                        }}
                        style={{ fontSize: 12 }}
                      >
                        Export as .csv
                      </MenuItem>
                      <MenuItem style={{ fontSize: 12 }} onClick={handleClose}>
                        Export as .xlsx
                      </MenuItem>
                      {UserProfileData()?.company_id === "99" && (
                        <MenuItem
                          style={{ fontSize: 12 }}
                          onClick={() => {
                            exportToXlsx();
                            handleClose();
                          }}
                        >
                          Export AAJ Fedex
                        </MenuItem>
                      )}
                    </Menu>
                  </div>
                </div>
              </div>

              <div className="table-contain w-100">
                <table className="shipments-table w-100">
                  <thead>
                    <tr>
                      <th scope="col">
                        <input
                          type="checkbox"
                          checked={
                            selectedShipments.length === shipments?.length
                          }
                          onClick={handleSelectAll}
                        />
                      </th>
                      <th scope="col">Sender</th>
                      <th scope="col">Drop-off Location</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Transporter</th>
                      <th scope="col">Shipment ID</th>
                      <th scope="col">Status</th>
                      <th scope="col">Date</th>
                    </tr>
                  </thead>
                  {isLoading === true ? null : (
                    <tbody>
                      {shipments?.map((shipment) => {
                        const dateTime = moment(shipment.date_time);
                        const timestamp = dateTime.format("ddd MMM Do, h:mm A");

                        const status =
                          shipment.status !== ""
                            ? shipment.status
                            : "Pending Delivery";
                        const statusClass = shipment.status
                          .toString()
                          .replaceAll(" ", "");

                        return (
                          <tr key={shipment.id}>
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedShipments.includes(
                                  shipment.shipment_no
                                )}
                                onClick={() =>
                                  handleShipmentToggle(shipment.shipment_no)
                                }
                              />
                            </td>
                            <td className="p-0">
                              <Link
                                to={`/shipment/${shipment.id}`}
                                style={{
                                  padding: "20px 10px 20px 10px",
                                  color: "#475367",
                                }}
                              >
                                <Tooltip
                                  title={`${shipment?.sender_name}`}
                                  placement="top"
                                >
                                  <span>
                                    {shortenTexts(shipment?.sender_name)}
                                  </span>
                                </Tooltip>
                              </Link>
                            </td>
                            <td
                              onClick={() => handleClick(shipment.id)}
                              // onContextMenu={() => openNewTab(shipment.id)}
                              className="p-0"
                            >
                              <Link
                                to={`/shipment/${shipment.id}`}
                                style={{
                                  padding: "20px 10px 20px 10px",
                                  color: "#475367",
                                }}
                              >
                                <Tooltip
                                  title={`${shipment?.drop_off_location}`}
                                  placement="top"
                                >
                                  <span>
                                    {shortenTexts(shipment?.drop_off_location)}
                                  </span>
                                </Tooltip>
                              </Link>
                            </td>
                            <td className="p-0">
                              <Link
                                to={`/shipment/${shipment.id}`}
                                style={{
                                  padding: "20px 10px 20px 10px",
                                  color: "#475367",
                                }}
                              >
                                {shipment?.currency === "USD"
                                  ? "$"
                                  : shipment?.currency === "NGN"
                                  ? "₦"
                                  : shipment?.currency === "GBP"
                                  ? "£"
                                  : shipment?.currency === "EUR"
                                  ? "€"
                                  : "₦"}
                                {parseFloat(
                                  shipment.total_amount
                                ).toLocaleString("en")}
                              </Link>
                            </td>
                            <td className="p-0">
                              <Link
                                to={`/shipment/${shipment.id}`}
                                style={{
                                  padding: "20px 10px 20px 10px",
                                  color: "#475367",
                                }}
                              >
                                <Tooltip
                                  title={`${
                                    shipment.processor === "Self"
                                      ? shipment.rider_name
                                        ? shipment.rider_name
                                        : "No Rider"
                                      : shipment.processor
                                  }`}
                                  placement="top"
                                >
                                  <span>
                                    {shipment.processor === "Self"
                                      ? shortenTexts(shipment.rider_name)
                                        ? shortenTexts(shipment.rider_name)
                                        : "No Rider"
                                      : shortenTexts(shipment.processor)}
                                  </span>
                                </Tooltip>
                              </Link>
                            </td>
                            <td className="p-0">
                              <Link
                                to={`/shipment/${shipment.id}`}
                                style={{
                                  padding: "20px 10px 20px 10px",
                                  color: "#475367",
                                }}
                              >
                                {shipment.shipment_no}
                              </Link>
                            </td>
                            <td className="p-0">
                              <Link
                                to={`/shipment/${shipment.id}`}
                                style={{
                                  padding: "20px 10px 20px 10px",
                                  color: "#475367",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <div className={`status ${statusClass}`}>
                                  {status}
                                </div>
                              </Link>
                            </td>
                            <td className="p-0">
                              <Link
                                to={`/shipment/${shipment.id}`}
                                style={{
                                  padding: "20px 10px 20px 10px",
                                  color: "#475367",
                                }}
                              >
                                {timestamp}
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
                {shipments.length < 1 && !isLoading && (
                  <EmptyScreen
                    message={"No shipment booked yet!"}
                    action={() => {
                      navigator("/create-shipment");
                    }}
                    actionText={"Book Shipment"}
                  />
                )}
              </div>
              {!isLoading && shipments.length > 0 && (
                <Pagination
                  total_pages={totalPages}
                  paginate={paginate}
                  nextPage={nextPage}
                  prevPage={prevPage}
                  current_page={currentPage}
                />
              )}

              {isLoading === false ? null : (
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />
                </LoadingContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shipments;
